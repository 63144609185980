<template>
  <div class="problemList">
    <el-carousel trigger="click" height="230px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <div class="banner">
          <el-image :src="item.img_url" fit="fill"></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
    <Breadcrumb></Breadcrumb>
    <detail :other_id="$route.query.id"></detail>
  </div>
</template>

<script>
import { carousel } from "@/api/common.js"
import Detail from "@/views/api/teaching/questOther/detail";
export default {
  components: {
    Detail,
  },
  props:['type'],
  data () {
    return {
      bannerList: [],
      matchType:'',
      imgId:"",
    }
  },
  methods: {
    routeUpdate(){
      this.id = this.$route.query.id
      carousel({ type: 14 })
          .then(({ data }) => {
            this.bannerList = data.list
            console.log(data, "比赛页面图片")
          })
          .catch((err) => {
            console.log(err)
          })
    },
  },
};
</script>

<style lang="less" scoped>

.banner {
  height: 100%;
  width: 100%;
  .el-image {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
}
</style>